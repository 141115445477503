export default function () {

	const downloadButton = document.querySelector('.m-documents__download-btn');
    
    downloadButton.addEventListener('click', function(event) {
        const reservationId = downloadButton.getAttribute('data-reservation-id');
        const reservationReference = downloadButton.getAttribute('booking-reference');
        const url = `/api/downloadBookingDocuments/${reservationId}`;

        downloadButton.innerHTML = "DOWNLOADING, PLEASE WAIT";
        downloadButton.disabled = true;

        $.ajax({
            url: url,
            type: 'GET',
            success: function (data) {
                const link = document.createElement('a');
                const objectURL = URL.createObjectURL(data);
                link.href = objectURL;
                link.download = `${reservationReference}-documents.zip`;
                link.click();
                URL.revokeObjectURL(objectURL);
                downloadButton.innerHTML = "DOWNLOAD ALL DOCUMENTS";
                downloadButton.disabled = false;
            },
            error: function (errorThrown) {
                console.error('Error downloading documents:', errorThrown);
                alert('There was an error downloading the documents.');
                downloadButton.innerHTML = "DOWNLOAD ALL DOCUMENTS";
                downloadButton.disabled = false;
            },
            xhrFields: {
                responseType: 'blob'
            }
        });
    });
}